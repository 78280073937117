<template>
  <div style="cursor: pointer" @click="$emit('exit')">
    <div :class="{ button: label }">
      <svg width="24" height="24" viewBox="-2 0 19 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M16 22V20.5C16 19.9477 15.5523 19.5 15 19.5C14.4477 19.5 14 19.9477 14 20.5V22H2L2 2H14V3.5C14 4.05228 14.4477 4.5 15 4.5C15.5523 4.5 16 4.05229 16 3.5V2C16 0.895431 15.1046 0 14 0H2C0.895431 0 0 0.895431 0 2V22C0 23.1046 0.895431 24 2 24H14C15.1046 24 16 23.1046 16 22Z"
          :fill="color"
        />
        <path
          d="M18.7071 11.2929L14.7071 7.29289C14.3166 6.90237 13.6834 6.90237 13.2929 7.29289C12.9024 7.68342 12.9024 8.31658 13.2929 8.70711L15.5858 11H7C6.44772 11 6 11.4477 6 12C6 12.5523 6.44772 13 7 13H15.5858L13.2929 15.2929C12.9024 15.6834 12.9024 16.3166 13.2929 16.7071C13.6834 17.0976 14.3166 17.0976 14.7071 16.7071L18.7071 12.7071C19.0976 12.3166 19.0976 11.6834 18.7071 11.2929Z"
          :fill="color"
        />
      </svg>

      <div v-if="label" class="label" :style="{ color: color }">
        {{ $t('accountTools.logOut') }} <span>&nbsp;|&nbsp; {{ operator.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    label: { default: false },
    colorInverse: { default: false },
  },

  computed: {
    color() {
      return this.colorInverse ? '#141116' : '#FFFFFF';
    },
    ...mapState({
      operator: (state) => state.layout.operator,
    }),
  },
};
</script>

<style scoped>
.button {
  display: flex;
  align-items: center;
  padding: 16px;
}
.label {
  text-transform: uppercase;
  padding-left: 16px;
  font-weight: 500;
}
.label span {
  color: #7d7d7d;
}
</style>
