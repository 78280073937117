<template>
  <b-modal id="editLangsModal" ref="modal" :title="$t('language.chooseMultiple')">
    <v-select v-model="chosenSystemLang" :options="processedSystemLangs">
      {{ $t('language.system') }}
    </v-select>

    <v-select v-model="chosenContentLang" :options="processedContentLangs">
      {{ $t('language.content') }}
    </v-select>

    <template #modal-footer>
      <button class="btn btn-success" @click="save">
        {{ $t('shared.save') }}
      </button>
      <button class="btn btn-outline-danger" @click="$bvModal.hide('editLangsModal')">
        {{ $t('shared.cancel') }}
      </button>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import VSelect from '@components/controls/VSelect/VSelect';

export default {
  name: 'EditLangsModal',
  components: { VSelect },
  props: {
    profileLangs: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      chosenSystemLang: this.profileLangs.system,
      chosenContentLang: this.profileLangs.content,
    };
  },
  computed: {
    ...mapGetters('langs', ['systemLangs', 'contentLangs']),
    processedSystemLangs() {
      return this.systemLangs.map((item) => ({ text: item.name, value: item.code }));
    },
    processedContentLangs() {
      return this.contentLangs.map((item) => ({ text: item.name, value: item.code }));
    },
  },
  methods: {
    save: async function () {
      this.$emit('saved', this.chosenSystemLang, this.chosenContentLang);
    },
  },
};
</script>
