<template>
  <div>
    <div v-if="hasManager" class="Manager" :class="{ colorInverse }">
      <div v-if="!mobile" class="Manager-desktop">
        <div class="Manager-contacts">
          <a :href="`mailto:${manager.email}`">{{ manager.email }}</a>
          <a :href="`tel:${manager.phone}`">{{ manager.phone | phone }}</a>
        </div>
        <div class="Manager-name">
          <span class="small">{{ $t('shared.yourManager') }}</span>
          <span>{{ manager.full_name }}</span>
        </div>
      </div>
      <div v-if="mobile" class="Manager-mobile">
        <div class="Manager-name">
          <span class="small">{{ $t('shared.yourManager') }}</span>
          <span>{{ manager.full_name }}</span>
        </div>
        <div class="Manager-contacts">
          <a :href="`mailto:${manager.email}`">{{ manager.email }}</a>
          <a :href="`tel:${manager.phone}`">{{ manager.phone | phone }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import phoneFilter from '../../filters/phone';

export default {
  props: {
    mobile: { default: false },
    colorInverse: { default: false },
  },

  computed: {
    ...mapState({
      manager: (state) => state.layout.manager,
    }),
    hasManager() {
      return this.manager.full_name && this.manager.phone && this.manager.email;
    },
  },

  filters: {
    phone: phoneFilter,
  },
};
</script>

<style scoped>
.Manager-desktop {
  display: flex;
  padding: 0 40px;
}
.Manager-contacts {
  padding-right: 16px;
  border-right: 1px solid #7d7d7d;
}
.Manager-contacts > a {
  display: block;
  color: #fff;
}
.Manager-name {
  padding-left: 24px;
}
.Manager-name > span {
  display: block;
}

.Manager-mobile {
  display: block;
  text-align: center;
}
.Manager-mobile .Manager-contacts {
  padding-right: 0px;
  border-right: none;
  font-weight: 500;
}
.Manager-mobile .Manager-contacts > a {
  display: block;
}
.Manager-mobile .Manager-name {
  padding-left: 0px;
  padding-bottom: 12px;
  font-size: 14px;
}
.Manager-mobile .Manager-name > span {
  display: block;
}

.colorInverse.Manager .Manager-contacts > a {
  color: #141116;
}
</style>
