var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isGuest
    ? _c("footer", { staticClass: "mas-footer" }, [
        _c("div", [
          _c(
            "div",
            { staticClass: "d-flex flex-column" },
            [
              _c("manager", { staticClass: "only-desktop" }),
              _vm._v(" "),
              _c("manager", {
                staticClass: "only-mobile mobile",
                attrs: { mobile: true },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "link" }, [
          _c("span", [
            _c(
              "a",
              {
                attrs: {
                  href: "https://lennufwiki.gitbook.io/wiki/",
                  target: "_blank",
                },
              },
              [_vm._v(_vm._s(_vm.$t("footer.personalAccountGuide")))]
            ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }