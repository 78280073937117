var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-group" },
    [
      _vm.$slots.default
        ? _c("label", { attrs: { for: _vm.inputId } }, [_vm._t("default")], 2)
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-form-select",
        _vm._g(
          _vm._b(
            {
              class: { "is-invalid": _vm.error },
              attrs: {
                id: _vm.inputId,
                "aria-describedby": `${_vm.inputId}-alert`,
                multiple: _vm.multiple,
                "select-size": _vm.selectSize,
                size: "size",
              },
            },
            "b-form-select",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        )
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "invalid-feedback",
          attrs: { id: `${_vm.inputId}-alert`, role: "alert" },
        },
        [
          _vm._t(
            "error",
            function () {
              return [_vm._v("\n      " + _vm._s(_vm.error) + "\n    ")]
            },
            { error: _vm.error }
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }