import NetService from '@scripts/services/net';
import Vue from 'vue';
import Vuex from 'vuex';
import langs from './modules/langs';
import currency from './modules/currency';
import merchant from './modules/merchant';
import ModalModule from './modules/modal';
import offerBuffer from './modules/offer-buffer';
import productBuffer from './modules/product-buffer';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    title: '',
    layout: {},
    env: {},
    routes: {},
    loaderShow: false,
    currencyOld: {
      name: '',
      shortName: '',
      symbol: '',
    },
  },
  modules: {
    productBuffer: productBuffer(),
    offerBuffer: offerBuffer(),
    modal: ModalModule(),
    langs,
    currency,
    merchant,
  },
  mutations: {
    loaderShow(state, loaderShow) {
      state.loaderShow = loaderShow;
    },
    title(state, data) {
      state.title = data;
    },
    env(state, data) {
      state.env = data;
    },
    layout(state, data) {
      state.layout = data;
    },
    routes(state, routes) {
      state.routes = routes;
    },
    currencyOld(state, currency) {
      state.currencyOld = currency;
    },
  },
  getters: {
    getRoute:
      (state) =>
      (name, params = {}) => {
        const r = state.routes[name];
        if (!r) return;
        const { uri } = NetService.prepareUri(r, params);
        return `/${uri.replace(/^\//, '')}`;
      },
  },
});
