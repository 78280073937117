import Services from '../../../scripts/services/services';

const initialState = {
  products: [],
};

export default function (newState = {}) {
  return {
    namespaced: true,
    state: Object.assign(initialState, newState),
    getters: {
      productInBuffer: (state) => (id) => {
        const { products } = state;
        return Object.values(products).indexOf(id) !== -1;
      },
      productsCount: (state) => {
        const { products } = state;
        return Object.values(products).length;
      },
      productsAll: (state) => {
        const { products } = state;
        return Object.values(products);
      },
    },
    mutations: {
      changeProductsList(state, newProductsList) {
        state.products = newProductsList;
      },
    },
    actions: {
      addToBuffer({ commit }, id) {
        let store = Services.store();
        let url = store.getters.getRoute('buffer.add');
        return Services.net()
          .post(
            url,
            {},
            {
              productId: id,
            }
          )
          .then((data) => {
            commit('changeProductsList', data.all);
            return data.all;
          });
      },
      removeFromBuffer({ commit }, id) {
        let store = Services.store();
        let url = store.getters.getRoute('buffer.remove');
        return Services.net()
          .post(
            url,
            {},
            {
              productId: id,
            }
          )
          .then((data) => {
            commit('changeProductsList', data.all);
            return data.all;
          });
      },
      clearBuffer({ commit }) {
        let store = Services.store();
        let url = store.getters.getRoute('buffer.clear');
        return Services.net()
          .post(url)
          .then(() => {
            commit('changeProductsList', []);
          });
      },
      setBuffer({ commit }, data) {
        commit('changeProductsList', data);
      },
    },
  };
}
