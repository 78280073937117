import Services from '../../../scripts/services/services';

const initialState = {
  offers: {
    on_sale: [],
    production: [],
    moderate: [],
    archive: [],
  },
};

export default function (newState = {}) {
  return {
    namespaced: true,
    state: Object.assign(initialState, newState),
    getters: {
      offerInBuffer: (state) => (payload) => {
        const { offers } = state;
        return Object.values(offers[payload.type]).indexOf(payload.id) !== -1;
      },
      offersCount: (state) => (type) => {
        const { offers } = state;
        return Object.values(offers[type]).length;
      },
      offersAll: (state) => (type) => {
        const { offers } = state;
        return Object.values(offers[type]);
      },
    },
    mutations: {
      changeOffersList(state, payload) {
        state.offers[payload.type] = payload.data;
      },
    },
    actions: {
      addToBuffer({ commit }, payload) {
        const store = Services.store();
        const url = store.getters.getRoute('offer.buffer.add');
        return Services.net()
          .post(url, {}, { type: payload.type, offerIds: payload.ids })
          .then((data) => {
            commit('changeOffersList', {
              type: payload.type,
              data: data.all,
            });
            return data.all;
          });
      },
      removeFromBuffer({ commit }, payload) {
        const store = Services.store();
        const url = store.getters.getRoute('offer.buffer.remove');
        return Services.net()
          .post(
            url,
            {},
            {
              type: payload.type,
              offerIds: payload.ids,
            }
          )
          .then((data) => {
            commit('changeOffersList', {
              type: payload.type,
              data: data.all,
            });
            return data.all;
          });
      },
      clearBuffer({ commit }, type) {
        const store = Services.store();
        const url = store.getters.getRoute('offer.buffer.clear');
        return Services.net()
          .post(
            url,
            {},
            {
              type,
            }
          )
          .then(() => {
            commit('changeOffersList', {
              type,
              data: [],
            });
          });
      },
      setBuffer({ commit }, payload) {
        commit('changeOffersList', {
          type: payload.type,
          data: payload.selectedOffers,
        });
      },
    },
  };
}
