<template>
  <div class="form-group">
    <label v-if="$slots.default" :for="inputId">
      <slot />
    </label>
    <b-form-select
      v-bind="$attrs"
      :id="inputId"
      :class="{ 'is-invalid': error }"
      :aria-describedby="`${inputId}-alert`"
      :multiple="multiple"
      :select-size="selectSize"
      size="size"
      v-on="$listeners"
    ></b-form-select>
    <span :id="`${inputId}-alert`" class="invalid-feedback" role="alert">
      <slot name="error" :error="error">
        {{ error }}
      </slot>
    </span>
  </div>
</template>

<script>
import inputMixin from '../../../mixins/input-mixin';

export default {
  name: 'VSelect',
  mixins: [inputMixin],
  props: {
    id: { type: String, default: '' },
    multiple: { type: String, default: null },
    selectSize: { type: Number, default: null },
    size: { type: String, default: 'sm' },
    error: { type: String, default: '' },
  },
  data() {
    return {
      inputId: this.id ? this.id : `v-input-id-${this._uid}`,
    };
  },
};
</script>
