<template>
  <!-- <nav class="navbar navbar-dark lennuf-navbar">
        <div class="d-flex" v-if="!isGuest">
            <span>
                <a href="/"><picture>
                    <img src="/images/logo.svg" width="128" height="46">
                </picture></a>
            </span>
            <span class="navbar-item navbar-divider">|</span>
            <span class="navbar-item">
                Merchant<br>
                Administration System
            </span>
        </div>
        <template >
            <div v-if="$mq.tabletLg" class="d-flex navbar-right" @click="menuOpened = !menuOpened">
                <img src="//via.placeholder.com/50?text=O" alt="avatar" class="navbar-item navbar-avatar">
                <fa-icon icon="angle-down" size="lg" class="navbar-item navbar-icon"></fa-icon>
            </div>
            <div v-else class="d-flex">
                <span class="navbar-item">
                    {{ $t('shared.yourManager') }}<br>
                    {{ manager.full_name }}
                </span>
                <span class="navbar-item navbar-divider">|</span>
                <span class="navbar-item">
                    <a :href="`mailto:${manager.email}`">{{ manager.email }}</a><br>
                    <a :href="`tel:${manager.phone}`">{{ preparePhone(manager.phone) }}</a>
                </span>

                <notifications/>

                <span class="navbar-item navbar-icon">
                    <fa-icon
                            icon="envelope"
                            size="lg"></fa-icon>
                </span>

                <img src="//via.placeholder.com/50?text=O" alt="avatar" class="navbar-item navbar-avatar">
                <span class="navbar-item">
                    {{ operator.name }}<br>
                    <a href="#" @click="logout">{{ $t('header.exit') }}</a>
                </span>
            </div>
        </template>

        <transition name="slide">
            <div v-if="$mq.tabletLg && menuOpened" class="mobile-menu">
                <div class="menu-block">
                    <span>{{ operator.name }}<br></span>
                    <span><a @click="logout">{{ $t('header.exit') }}</a></span>
                </div>
                <div class="menu-block">
                    <span><fa-icon icon="comment-dots" size="lg"></fa-icon> {{ $t('header.onlineChat') }}</span>
                    <span><fa-icon icon="envelope" size="lg"></fa-icon> {{ $t('header.contactUs') }}</span>
                </div>

                <span>{{ $t('shared.yourManager') }}</span>
                <span>{{ manager.full_name }}</span>
                <span><a :href="`mailto:${manager.email}`">{{ manager.email }}</a></span>
                <span><a :href="`tel:${manager.phone}`">{{ preparePhone(manager.phone) }}</a></span>
            </div>
        </transition>
    </nav> -->
  <div class="Header-layout">
    <div class="Header">
      <div class="Header-logo only-desktop">
        <a href="/">
          <picture>
            <source srcset="/logo/logotype.webp" type="image/webp" />
            <img src="/logo/logotype.png" />
          </picture>
        </a>
      </div>
      <div v-if="!isGuest" class="Header-tools">
        <div class="Header-leftBar">
          <manager class="only-desktop" />
          <div class="only-desktop">Merchant Administration System</div>
          <div class="Header-menuBtn only-mobile" @click="menuOpened = !menuOpened">
            <img src="/images/menu.svg" />
          </div>
        </div>
        <div class="Header-rightBar">
          <notifications />
          <mail />
          <lang-manager />
          <sign-out class="only-desktop" @exit="logout" />
        </div>
      </div>
    </div>

    <!-- <div class="Header-menu only-desktop" v-if="!isGuest">
            <MainMenu></MainMenu>   
        </div> -->
    <mobile-modal v-if="!isGuest" :is-open="menuOpened" @close="menuOpened = false">
      <template #header>{{ $t('header.menu') }}</template>
      <template #default>
        <MainMenu></MainMenu>
      </template>
    </mobile-modal>
  </div>
</template>

<script>
import Services from '@scripts/services/services';
import modalMixin from '@mixins/modal';
import Manager from '@components/account-tools/manager';
import Notifications from '@components/account-tools/notifications';
import Mail from '@components/account-tools/mail';
import LangManager from '@components/account-tools/lang-manager';
import SignOut from '@components/account-tools/sign-out';
import MainMenu from '@components/main-menu/main-menu';
import MobileModal from '@components/modal-mobile';

export default {
  name: 'LayoutHeader',
  components: { Manager, Notifications, Mail, LangManager, SignOut, MainMenu, MobileModal },
  mixins: [modalMixin],
  props: {
    onIndex: { type: Boolean, default: false },
  },
  data() {
    return {
      menuOpened: false,
      messagesOpened: false,
    };
  },
  methods: {
    logout() {
      Services.net()
        .post(this.route('logout'))
        .then(
          () => {
            window.location.href = this.route('page.login');
          },
          () => {
            this.showMessageBox({ title: this.$t('shared.error'), text: this.$t('header.error') });
          }
        );
    },
  },
};
</script>
<style>
.Header-layout .only-mobile {
  display: none;
}

.Header-layout .only-desktop {
  display: block;
}

@media screen and (max-width: 1023px) {
  .Header-layout .only-mobile {
    display: block;
  }

  .Header-layout .only-desktop {
    display: none;
  }
}

.Header {
  display: flex;
  background-color: var(--header-background-color) !important;
  color: var(--header-font-color) !important;
  font-size: 14px;
  line-height: 16px;
}

.Header svg {
  color: var(--header-font-color) !important;
}

.Header svg path {
  fill: var(--header-font-color) !important;
}

.Header a,
.Header a:hover {
  color: #fff;
}

.small {
  color: #7d7d7d;
  font-size: 12px;
}

.Header-logo {
  height: 60px;
  padding: 5px 0;
  margin-left: 1.25rem;
}

.Header-logo img {
  height: 100%;
}

.Header-logo .large {
  display: auto;
}

.Header-logo .small {
  display: none;
}

@media screen and (max-width: 1290px) {
  .Header-logo .large {
    display: none;
  }

  .Header-logo .small {
    display: inline-block;
  }
}

.Header-tools {
  display: flex;
  width: 100%;
  height: 60px;
  align-items: center;
}

.Header-tools .only-mobile {
  display: none;
}

.Header-tools .only-desktop {
  display: inline-block;
}

@media screen and (max-width: 1023px) {
  .Header,
  .Header-logo,
  .Header-tools {
    height: 48px;
  }

  .Header-logo {
    line-height: 48px;
    height: 29px;
  }

  .Header-tools .only-mobile {
    display: inline-block;
  }

  .Header-tools .only-desktop {
    display: none;
  }
}

.Header-tools > div {
}

.Header-menuBtn {
  cursor: pointer;
  padding-left: 16px;
}

.Header-rightBar {
  padding-right: 41px;
  margin-left: auto;
}

.Header-rightBar > div {
  display: inline-block;
  padding-left: 28px;
}

@media screen and (max-width: 1023px) {
  .Header-rightBar {
    padding-right: 16px;
  }

  .Header-rightBar > div {
    display: inline-block;
    padding-left: 22px;
  }
}
</style>
