export default {
  namespaced: true,

  state: {
    info: null,
    currency: null,
  },

  getters: {
    getInfo: (state) => state.info,
    getCurrency: (state) => state.currency,
    getCurrencyAbbreviation: (state) => state.currency?.abbreviation,
  },

  mutations: {
    SET_INFO(state, payload) {
      state.info = payload;
    },
    SET_CURRENCY(state, payload) {
      state.currency = payload;
    },
  },

  actions: {
    setMerchant({ commit, rootGetters }, { id, legal_name: name, currency_id: currencyId }) {
      if (!id) return;

      commit('SET_INFO', { id, name, currencyId });

      const currencies = rootGetters['currency/getCurrencies'];
      const merchantCurrency = currencies.find((el) => el.id === currencyId);

      commit('SET_CURRENCY', merchantCurrency);
    },
  },
};
