<template>
  <div class="horizontal-menu-item">
    <span v-if="item.items" class="menu-text heading" :class="{ active: active }" :style="indent" @click="toggleItem()">
      {{ item.title }}
      <fa-icon :icon="opened ? 'angle-down' : 'angle-right'" class="float-right shevron"></fa-icon>
    </span>
    <a
      v-else
      :href="item.route"
      class="menu-text"
      :class="{ active: active, empty: item.route === '#' }"
      :style="indent"
      >{{ item.title }}</a
    >
    <!-- <transition name="slide"> -->
    <div v-if="item.items" class="menu-items">
      <menu-item v-for="(subitem, index) in item.items" :key="index" :item="subitem" :depth="depth + 1"></menu-item>
    </div>
    <!-- </transition> -->
  </div>
</template>

<script>
import MenuItem from './menu-item.vue';

export default {
  components: {
    MenuItem,
  },
  props: {
    item: {},
    depth: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      toggle: null,
    };
  },
  computed: {
    indent() {
      return {
        'padding-left': `${16 * this.depth}px`,
      };
    },
    active() {
      return this.checkItemsHasActive(this.item);
    },
    opened() {
      if (this.toggle === null) {
        return this.active;
      } else {
        return this.toggle;
      }
    },
  },
  methods: {
    checkItemsHasActive(item) {
      let result = false;
      if (item.active) {
        result = true;
      } else {
        if (item.items) {
          for (let subitem of item.items) {
            if (this.checkItemsHasActive(subitem)) {
              result = true;
              break;
            }
          }
        }
      }
      return result;
    },
    toggleItem() {
      if (this.item.route) {
        return;
      } else {
        if (this.toggle === null) {
          this.toggle = !this.active;
        } else {
          this.toggle = !this.toggle;
        }
      }
    },
  },
};
</script>

<style scoped>
.horizontal-menu-item {
  position: relative;
  display: inline-block;
}

.menu-items {
  position: absolute;
  top: 45px;
  display: none;
  z-index: 9999999;
  width: auto;
  background-color: #fff;
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.1);
}

.horizontal-menu-item:hover .menu-items {
  display: block;
}

.menu-item:first-of-type {
  color: red;
}

.menu-text {
  display: block;
  padding: 16px 20px;
  color: #141116;
  user-select: none;
}

.heading {
  text-transform: uppercase;
  font-weight: 500;
  color: #141116;
}

.active {
  text-decoration: underline;
}

.shevron {
  display: none;
}
</style>
