import '@/app';
import initCurrencyPlugin from '@/plugins/currency';
import { i18n, loadDefaultLocale, setLocaleAsync } from '@/plugins/i18n';
import media from '@/plugins/MediaPlugin';
import store from '@/store/store';
import Vue from 'vue';

export default async function boot(pageComponent, options = {}) {
  await initCurrencyPlugin({ store });
  await loadDefaultLocale();

  const locale = store.getters['langs/systemLang'];
  await setLocaleAsync(locale);

  // Boot the current Vue component
  const root = document.getElementById('app');

  await store.dispatch('merchant/setMerchant', JSON.parse(root.dataset.merchant) ?? {});

  const vueOptions = Object.assign(
    {
      store,
      mq: media,
      i18n,
      render: (h) =>
        h(pageComponent, {
          props: JSON.parse(root.dataset.props),
        }),
    },
    options
  );
  window.vue = new Vue(vueOptions).$mount(root);
}
