var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "button", on: { click: _vm.openEditLangsModal } },
        [_c("span", [_vm._v(_vm._s(_vm.systemLang))])]
      ),
      _vm._v(" "),
      _c("edit-langs-modal", {
        attrs: { "profile-langs": _vm.profileLangs },
        on: { saved: _vm.saveLangs },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }