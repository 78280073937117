var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modal",
      attrs: { id: "editLangsModal", title: _vm.$t("language.chooseMultiple") },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c(
                "button",
                { staticClass: "btn btn-success", on: { click: _vm.save } },
                [_vm._v("\n      " + _vm._s(_vm.$t("shared.save")) + "\n    ")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-outline-danger",
                  on: {
                    click: function ($event) {
                      return _vm.$bvModal.hide("editLangsModal")
                    },
                  },
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t("shared.cancel")) + "\n    "
                  ),
                ]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "v-select",
        {
          attrs: { options: _vm.processedSystemLangs },
          model: {
            value: _vm.chosenSystemLang,
            callback: function ($$v) {
              _vm.chosenSystemLang = $$v
            },
            expression: "chosenSystemLang",
          },
        },
        [_vm._v("\n    " + _vm._s(_vm.$t("language.system")) + "\n  ")]
      ),
      _vm._v(" "),
      _c(
        "v-select",
        {
          attrs: { options: _vm.processedContentLangs },
          model: {
            value: _vm.chosenContentLang,
            callback: function ($$v) {
              _vm.chosenContentLang = $$v
            },
            expression: "chosenContentLang",
          },
        },
        [_vm._v("\n    " + _vm._s(_vm.$t("language.content")) + "\n  ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }