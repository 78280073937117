import { setLocaleAsync } from '@/plugins/i18n';

export default {
  namespaced: true,
  state: {
    contentLangs: [],
    systemLangs: [],
    systemLang: null,
    contentLang: null,
  },
  mutations: {
    setContentLangs(state, { langs }) {
      state.contentLangs = langs;
    },
    setSystemLangs(state, { langs }) {
      state.systemLangs = langs;
    },
    setSystemLang(state, { lang }) {
      state.systemLang = lang;
    },
    setContentLang(state, { lang }) {
      state.contentLang = lang;
    },
  },
  getters: {
    contentLangs: (state) => state.contentLangs,
    contentLangsFormatted: (state) => {
      return Object.values(state.contentLangs).reduce((acc, el) => ({ ...acc, [el.code]: el }), {});
    },
    systemLangs: (state) => state.systemLangs,
    systemLangsFormatted: (state) => {
      return Object.values(state.systemLangs).reduce((acc, el) => ({ ...acc, [el.code]: el }), {});
    },
    systemLang: (state) => state.systemLang,
    contentLang: (state) => state.contentLang,
  },
  actions: {
    setContentLangs({ commit }, data) {
      commit('setContentLangs', data);
    },
    setSystemLangs({ commit }, data) {
      commit('setSystemLangs', data);
    },
    async setSystemLang({ commit }, data) {
      await setLocaleAsync(data.lang);
      commit('setSystemLang', data);
    },
    setContentLang({ commit }, data) {
      commit('setContentLang', data);
    },
  },
};
