import Vue from 'vue';
import { capitalize } from '@/helpers/string';

const prefix = '[Currency error]:';

const priceTypeError = (value) => {
  const error = `Invalid price: type check failed for price. 
  Expected Object, got ${capitalize(typeof value)} with value ${value}.`;

  return new TypeError(`${prefix} ${error}`);
};
const priceReferenceError = (value) => {
  const error = `Invalid price: object check failed for price. 
  Object should be contain "value" and "currencyId", got Object with value ${JSON.stringify(value)}.`;

  return new ReferenceError(`${prefix} ${error}`);
};

export default async function initCurrencyPlugin({ store }) {
  await store.dispatch('currency/fetchCurrencies');

  Vue.filter('price', function (price) {
    try {
      if (typeof price !== 'object') throw priceTypeError(price);
      if (!('value' in price && 'currency_id' in price)) throw priceReferenceError(price);

      const currencies = store.getters['currency/getCurrencies'];
      const currency = currencies.find((el) => el.id === price.currency_id);

      const isNegativeNumber = price.value < 0;
      const value = Math.abs(parseFloat(price.value)).toFixed(currency.precision);
      const valueFormatted = new Intl.NumberFormat('ru-RU', {}).format(value);
      const symbol = currency.symbol || currency.abbreviation;
      const separator = currency.symbol_delimiter ? '\u202F' : '';

      const priceArr = [valueFormatted];

      if (currency.is_symbol_right) priceArr.push(symbol);
      else priceArr.unshift(symbol);

      const result = priceArr.join(separator);

      return isNegativeNumber ? `-\u00A0${result}` : result;
    } catch (e) {
      console.error(e.message);
      return price?.value ?? price;
    }
  });
}
