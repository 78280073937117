import Services from '@scripts/services/services';

export default {
  namespaced: true,

  state: {
    currencies: [],
  },

  getters: {
    getCurrencies: (state) => state.currencies,
    getCurrencySymbol: (state) => (id) => {
      const currency = state.currencies.find((el) => el.id === id);
      return currency.symbol || currency.abbreviation;
    },
    getCurrencyAbbreviation: (state) => (id) => state.currencies.find((el) => el.id === id)?.abbreviation,
  },

  mutations: {
    SET_CURRENCIES(state, payload) {
      state.currencies = payload;
    },
    SET_MERCHANT_CURRENCY(state, payload) {
      state.merchantCurrency = payload;
    },
  },

  actions: {
    async fetchCurrencies({ commit, rootGetters }) {
      try {
        const { currency } = await Services.net().get(rootGetters['getRoute']('currency'));
        commit('SET_CURRENCIES', currency);
      } catch (error) {
        console.error(error);
      }
    },
  },
};
