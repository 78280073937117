import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import BootstrapVue from 'bootstrap-vue';
import 'core-js/modules/es.array.iterator';
import 'core-js/modules/es.promise';
import 'lazysizes';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/respimg/ls.respimg';
import * as moment from 'moment';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import 'whatwg-fetch';
import '../scripts/common';
import { capitalize, formatSize, integer, lowercase, truncate } from '../scripts/filters';
import Helpers from '../scripts/helpers';
import Services from '../scripts/services/services';
import './fontawesome';
import store from './store/store';

Vue.use(BootstrapVue);

// Font Awesome Icons
Vue.component('FaIcon', FontAwesomeIcon);

// Filters
Vue.filter('capitalize', capitalize);
Vue.filter('lowercase', lowercase);
Vue.filter('truncate', truncate);
Vue.filter('formatSize', formatSize);
Vue.filter('integer', integer);

// Boot the current Vue component
const root = document.getElementById('app');

store.commit('layout', JSON.parse(root.dataset.layout));
store.commit('env', JSON.parse(root.dataset.env));
store.commit('title', root.dataset.title);
store.commit('routes', JSON.parse(root.dataset.routes));
store.commit('langs/setSystemLang', { lang: root.dataset.systemlang });
store.commit('langs/setContentLang', { lang: root.dataset.contentlang });
store.commit('langs/setContentLangs', { langs: JSON.parse(root.dataset.langscontent) });
store.commit('langs/setSystemLangs', { langs: JSON.parse(root.dataset.langssystem) });

store.commit('currencyOld', {
  name: 'сўм', // Рубли
  shortName: 'сўм', // руб.
  symbol: 'сўм', // ₽
}); // TODO: нужно передавать с бэка

Services.instance().register('store', () => {
  return store;
});

Services.instance().register('event', () => {
  return new Vue();
});

moment.locale('ru');
Vue.mixin({
  methods: {
    preparePrice(number, decimals, dec_point, thousands_sep) {
      return Helpers.preparePrice(number, decimals, dec_point, thousands_sep);
    },
    preparePhone(str) {
      // Filter only numbers from the input
      const cleaned = `${str}`.replace(/\D/g, '');

      // Check if the input is of correct length
      const match = cleaned.match(/^(\d)(\d{3})(\d{3})(\d{2})(\d{2})$/);

      if (match) {
        return `+${match[1]}(${match[2]}) ${match[3]}-${match[4]}-${match[5]}`;
      }

      return '';
    },
    pluralForm(n, forms) {
      return Helpers.plural_form(n, forms);
    },
    strToList(str) {
      if (!str) {
        return [];
      }
      return String(str).split('\n');
    },
    route(name) {
      return `/${this.$store.state.routes[name].replace(/^\//, '')}`;
    },
    datePrint(date) {
      return moment(date, 'YYYY-MM-DD').format('LL');
    },
    datetimePrint(date) {
      return moment(date, 'YYYY-MM-DD HH:mm:ss').format('LLL');
    },
    printDoc(src) {
      let iframe = this._printIframe;
      if (!this._printIframe) {
        iframe = this._printIframe = document.createElement('iframe');
        document.body.appendChild(iframe);

        iframe.style.display = 'none';
        iframe.onload = function () {
          setTimeout(function () {
            iframe.focus();
            iframe.contentWindow.print();
          }, 1);
        };
      }

      iframe.src = src;
    },
  },
  computed: {
    ...mapGetters(['getRoute']),
    staticText() {
      return this.$store.state.layout.staticBlock;
    },
    isGuest() {
      return this.$store.state.layout.isGuest;
    },
    /** @return {DeliveryType} */
    discountTypes() {
      return this.$store.state.layout.discountTypes;
    },
  },
});

/**
 @typedef DeliveryType
 @type {Object}
 @property {integer} offer
 @property {integer} bundleOffer
 @property {integer} bundleMasterclass
 @property {integer} brand
 @property {integer} category
 @property {integer} masterclass
 @property {integer} delivery
 @property {integer} cartTotal
 @property {integer} anyOffer
 @property {integer} anyBundle
 @property {integer} anyBrand
 @property {integer} anyCategory
 @property {integer} anyMasterclass
 */
