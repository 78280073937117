<template>
  <nav class="sidebar">
    <div class="mobile">
      <div class="menu">
        <menu-item v-for="(item, index) in menu" :key="index" :item="item"></menu-item>
      </div>
      <div class="footer">
        <div class="tools">
          <sing-out :label="true" :color-inverse="true" />
          <notifications :label="true" :color-inverse="true" />
          <mail :label="true" :color-inverse="true" />
        </div>
        <div class="manager">
          <manager :mobile="true" :color-inverse="true" />
        </div>
      </div>
    </div>
    <div class="desktop">
      <HorizontalMenuItem v-for="(item, index) in menu" :key="index" :item="item"></HorizontalMenuItem>
    </div>
  </nav>
</template>

<script>
import MenuItem from './menu-item.vue';
import SingOut from '../account-tools/sign-out.vue';
import Notifications from '../account-tools/notifications.vue';
import Mail from '../account-tools/mail.vue';
import Manager from '../account-tools/manager.vue';
import HorizontalMenuItem from './horizontal-menu-item.vue';

export default {
  name: 'MainMenu',
  components: {
    MenuItem,
    SingOut,
    Notifications,
    Mail,
    Manager,
    HorizontalMenuItem,
  },
  computed: {
    menu() {
      return this.$store.state.layout.menu;
    },
  },
};
</script>
<style>
.sidebar {
  background-color: #fbfbfb !important;
}

.mobile {
  display: none;
}

.desktop {
  display: block;
  padding-left: 12px;
}

@media screen and (max-width: 1023px) {
  .sidebar {
    background-color: #fff !important;
  }

  .mobile {
    display: block;
  }

  .desktop {
    display: none;
  }
}

.menu,
.footer .tools {
  padding-bottom: 16px;
  border-bottom: 1px solid #eff1f3;
}

.footer .tools {
  padding-top: 16px;
}

.footer .manager {
  padding: 36px 0 45px 0;
}
</style>
