<template>
  <div>
    <div class="button" @click="openEditLangsModal">
      <span>{{ systemLang }}</span>
    </div>

    <edit-langs-modal :profile-langs="profileLangs" @saved="saveLangs" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import EditLangsModal from '@components/account-tools/edit-langs-modal';
import Services from '@scripts/services/services';

export default {
  name: 'LangManager',
  components: { EditLangsModal },
  computed: {
    ...mapGetters('langs', ['systemLang', 'contentLang']),
    profileLangs() {
      return {
        system: this.systemLang,
        content: this.contentLang,
      };
    },
  },
  methods: {
    ...mapActions('langs', ['setSystemLang', 'setContentLang']),
    async saveLangs(chosenSystemLang, chosenContentLang) {
      if (chosenSystemLang === this.systemLang && chosenContentLang === this.contentLang) {
        Services.msg(this.$t('shared.changes.saved'));
        return;
      }
      Services.showLoader();
      try {
        await Services.net().post(this.getRoute('language.update'), {
          systemLang: chosenSystemLang,
          contentLang: chosenContentLang,
        });
        this.$bvModal.hide('editLangsModal');
        Services.msg(this.$t('shared.changes.saved'));
        // TODO refactor
        location.reload();
      } catch (error) {
        Services.msg(error, 'danger');
      } finally {
        Services.hideLoader();
      }
    },
    openEditLangsModal() {
      this.$bvModal.show('editLangsModal');
    },
  },
};
</script>

<style scoped>
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: solid 2px var(--header-font-color) !important;
  border-radius: 3px;
  height: 24px;
  width: 24px;
  cursor: pointer;
}
</style>
