var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("a", { attrs: { href: "/communications/chats" } }, [
      _c("div", { class: { button: _vm.label } }, [
        _c(
          "svg",
          {
            attrs: {
              width: "24",
              height: "16",
              viewBox: "0 0 24 16",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg",
            },
          },
          [
            _c("path", {
              attrs: {
                "fill-rule": "evenodd",
                "clip-rule": "evenodd",
                d: "M2 0C0.89543 0 0 0.895431 0 2L0 14C0 15.1046 0.895431 16 2 16L22 16C23.1046 16 24 15.1046 24 14L24 2C24 0.89543 23.1046 0 22 0L2 0ZM20.4569 2L3.54314 2L12 9.65145L20.4569 2ZM2 3.30093L2 14L22 14L22 3.30095L13.3418 11.1345C12.58 11.8238 11.42 11.8238 10.6582 11.1345L2 3.30093Z",
                fill: _vm.color,
              },
            }),
          ]
        ),
        _vm._v(" "),
        _vm.label
          ? _c("div", { staticClass: "label", style: { color: _vm.color } }, [
              _vm._v(_vm._s(_vm.$t("accountTools.messages"))),
            ])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }